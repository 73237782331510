<template>
  <div class="common-layout">
    <el-container v-loading="this.loading">
      <el-header>
        <el-row>
          <el-col>
            <el-header>XLSX to JSON</el-header>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-text class="mx-1">Обработка может занять несколько минут!</el-text>
          </el-col>
        </el-row>

      </el-header>
      <el-main>
        <label class="el-button el-button--primary">
          Загрузить
          <input type="file" id="input"/>
        </label>
        <el-button type="primary" v-on:click="loadXLSX">Скачать JSON</el-button>
      </el-main>
    </el-container>
  </div>

</template>

<script>
import readXlsxFile from 'read-excel-file'
import {ElMessageBox} from 'element-plus'
import {ElNotification} from 'element-plus'

export default {
  name: 'App',
  data: () => {
    return {
      arrayInKeys: {},
      arrayInData: {},
      assocObject: {},
      loaded: false,
      EventKeys: ["UP1", "UP1P", "PTK1", "UP2", "PTK2", "UP3", "PTK3"],
      BreedFullListKeys: ["ARD1", "KF1", "MR1", "AMZ1", "H1", "D1", "PSP1", "KIL1", "SKAL1", "SPS1", "TUR1H1",
        "ARD2", "KF2", "MR2", "AMZ2", "H2", "D2", "PSP2", "KIL2", "SKAL2", "SPS2", "TUR1H2", "ARD3",
        "KF3", "MR3", "AMZ3", "H3", "D3", "PSP3", "KIL3", "SKAL3", "SPS3", "TUR1H3", "ARD4", "KF4", "MR4", "AMZ4", "H4",
        "D4", "PSP4", "KIL4", "SKAL4", "SPS4", "TUR1H4", "ARD5", "KF5", "MR5", "AMZ5", "H5", "D5", "PSP5", "KIL5",
        "SKAL5", "SPS5", "TUR1H5", "ARD6", "KF6", "MR6", "AMZ6", "H6", "D6", "PSP6", "KIL6", "SKAL6", "SPS6", "TUR1H6",
        "ARD7", "KF7", "MR7", "AMZ7", "H7", "D7", "PSP7", "KIL7", "SKAL7", "SPS7", "TUR1H7", "ARD8", "KF8", "MR8", "AMZ8",
        "H8", "D8", "PSP8", "KIL8", "SKAL8", "SPS8", "TUR1H8", "ARD9", "KF9", "MR9", "AMZ9", "H9", "D9", "PSP9", "KIL9",
        "SKAL9", "SPS9", "TUR1H9", "ARD10", "KF10", "MR10", "AMZ10", "H10", "D10", "PSP10", "KIL10", "SKAL10", "SPS10",
        "TUR1H10"],
      BreedKeys: ["ARD", "KF", "MR", "AMZ", "H", "D", "PSP", "KIL", "SKAL", "SPS", "TUR1H", "TUR1H"],
      TeenKeys: ["KOL31", "H31", "AMZ31", "KF131", "MR131", "KF231", "MR231", "KF331", "MR331", "OP31"],
      UndergrowthKeys: ["STG32", "MR132", "MR232", "MR332"],

      //layout11: [{"DM11":0, "DM12":0, "DM13":0, "DM14":0, "DM15":0, "DM16":0, "DM17":0, "DM18":0}]
      layouts: {
        layout11: [],
        layout11ObjectOne: ["DM11", "DM12", "DM13", "DM14", "DM15", "DM16", "DM17", "DM18"],

        // layout12: [{"DM21": 0,"DM22": 0, "DM23":0, layoutInside: [{"DM24","DM25"},{"DM26", "DM27"}] }]
        layout12: ["DM21", "DM22", "DM23"],
        layout12ObjectOne: ["DM24", "DM25"],
        layout12ObjectTwo: ["DM26", "DM27"],

        layout13: ["DM31", "DM32", "DM33", "DM34", "DM35", "DM36", "DM37", "DM38"],
        layout14: ["DM41", "DM42", "DM43", "DM44", "DM45", "DM46", "DM47", "DM48"],
        //layout15: [{"DM51": 0, "DM52": 0, "DM55": 0, "DM56": 0, "DM57": 0, "DM58": 0, layoutInside: [{"DM53": 0, "DM54": 0}] }]
        layout15: ["DM51", "DM52", "DM55", "DM56", "DM57", "DM58"],
        layout15ObjectOne: ["DM53", "DM54"],

        layout16: ["DM61", "DM62", "DM63", "DM64", "DM65", "DM66"],
        layout17: ["DM71", "DM72", "DM73", "DM74", "DM75", "DM76"],
        layout18: ["DM81", "DM82", "DM83", "DM84", "DM85", "DM86", "DM87", "DM88"],
        layout19: ["DM91", "DM92", "DM93", "DM94", "DM95"],
        layout20: ["DM101", "DM102", "DM103", "DM104", "DM105", "DM106", "DM107"],
        layout21: ["DM201", "DM202", "DM203", "DM204", "DM205", "DM206", "DM207", "DM208"],
        //layout23 [{"DM301": "0"}]
        layout23: [],
        layout23ObjectOne: ["DM301"],

        layout24: ["DM401", "DM402", "DM403", "DM404", "DM405", "DM406"],
        layout25: ["DM501", "DM502", "DM503", "DM504", "DM505"],
        layout27: ["DM601", "DM602", "DM603", "DM604", "DM605", "DM606", "DM607", "DM608"],
        layout28: ["DM701", "DM702", "DM703"],
        layout29: ["DM801", "DM802", "DM803", "DM804", "DM805", "DM806", "DM807"]
      },

      layoutFullArray: ["M1", "DM11", "DM12", "DM13", "DM14", "DM15", "DM16", "DM17", "DM18", "M2", "DM21", "DM22",
        "DM23", "DM24", "DM25", "DM26", "DM27", "DM28", "M3", "DM31", "DM32", "DM33", "DM34", "DM35", "DM36",
        "DM37", "DM38", "M4", "DM41", "DM42", "DM43", "DM44", "DM45", "DM46", "DM47", "DM48", "M5", "DM51",
        "DM52", "DM53", "DM54", "DM55", "DM56", "DM57", "DM58", "M6", "DM61", "DM62", "DM63", "DM64", "DM65",
        "DM66", "DM67", "DM68", "M7", "DM71", "DM72", "DM73", "DM74", "DM75", "DM76", "DM77", "DM78", "M8",
        "DM81", "DM82", "DM83", "DM84", "DM85", "DM86", "DM87", "DM88"],

      needleFieldArray: ["WKT", "MK", "KV", "REL", "SKNR", "PL", "ZK", "SKP", "EKS", "POLL",
        "AUJL", "ERR", "LAIP", "MR", "VMR", "BON", "MTIP", "DTG", "KMET", "KSK", "PKSK", "DP", "SUVL",
        "SVTB", "SVTL", "SUX", "AGR", "AKL"],

      layoutId: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 25, 27, 28, 29],
      outJSON: [],
      loading: false
    }
  },
  methods: {
    loadXLSX() {
      this.loading = true
      const input = document.getElementById('input')

      if (input.files[0]) {
        try {
          readXlsxFile(input.files[0]).then((rows) => {
            this.arrayInKeys = rows.shift()
            this.arrayInData = rows
          }).then(() => this.loaded = true).then(() => this.createAssoc()).then(() => this.success('Загружено')).then(() => this.JSONCooking())
        } catch (e) {
          this.modal('Ошибка в структуре файла')
        }
      } else {
        this.modal('Выберите файл')
      }
    },
    createAssoc() {
      let r = []
      let keys = this.arrayInKeys
      this.arrayInData.forEach(function (line) {
            let oneLine = {}
            line.map((item, i) => ({[keys[i]]: item})).forEach(item => Object.assign(oneLine, item))
            r.push(oneLine)
          }
      )
      for (const [key, value] of Object.entries(r[0])) {
        if (value === 0) {
          r[0][key] = null
        }
      }
      this.assocObject = r
    },
    modal(text) {
      ElMessageBox.alert(text)
    },
    success(text) {
      ElNotification({
        title: 'Успешно',
        message: text,
        type: 'success',
      })
      this.loading = false
    },
    JSONCooking() {
      let JSONOut = []
      let JSONOneString = {}
      let mapping = this.mapping
      this.assocObject.forEach(function (r) {
        JSONOneString = mapping(r)
        JSONOut.push(JSONOneString)
      })
      this.outJSON = JSONOut
      this.downloadAsJSON(JSONOut)
    },
    mapping(currentLineObject) {
      // let currentLineObject = this.assocObject[0]
      let outObject = {}
      let layoutsObject = {}
      // let layoutID = parseInt(currentLineObject.M1)

      let eventsArray = []
      let breedsArray = []
      let teensArray = []
      let undergrowthArray = []

      //Собираем основу нового обьекта
      outObject = this.filterObjectByKeys(this.needleFieldArray, currentLineObject)

      //Собираем вложенные массивы отдельно
      eventsArray.push(this.getEventsArray(currentLineObject))
      breedsArray = this.breedFilter(currentLineObject)
      teensArray.push(this.filterObjectByKeys(this.TeenKeys, currentLineObject))
      undergrowthArray.push(this.filterObjectByKeys(this.UndergrowthKeys, currentLineObject))

      Object.assign(outObject, {'events': eventsArray})
      Object.assign(outObject, {'breeds': breedsArray})
      Object.assign(outObject, {'teens': teensArray})
      Object.assign(outObject, {'undergrowth': undergrowthArray})

      layoutsObject = this.getLayoutArray(currentLineObject)

      Object.assign(outObject, layoutsObject)

      return outObject

    },
    filterObjectByKeys(keys, obj) {
      return keys.reduce((cur, key) => {
        return Object.assign(cur, {[key]: obj[key]})
      }, {})
    },
    filterBreedObjectByKeys(keys, obj) {
      return keys.reduce((cur, key) => {
        return Object.assign(cur, {[key.slice(0, -1)]: obj[key]})
      }, {})
    },
    filterBreedObjectByKeysTen(keys, obj) {
      return keys.reduce((cur, key) => {
        return Object.assign(cur, {[key.slice(0, -2)]: obj[key]})
      }, {})
    },
    breedFilter(currentLineObject) {
      let breedObject = []
      let breedsCount = 11
      for (let i = 1; i < breedsCount; i++) {
        let keys = this.BreedKeys.map(r => r + i)
        if (i === 10) {
          breedObject.push(this.filterBreedObjectByKeysTen(keys, currentLineObject))
        } else {
          breedObject.push(this.filterBreedObjectByKeys(keys, currentLineObject))
        }
      }
      return breedObject
    },
    getLayoutArray(currentLineObject) {
      let tempFullArray = []
      let ids = this.layoutId
      let layouts = this.layouts
      for (let i = 1; i < 9; i++) {
        let tempArray = []
        tempArray.push(currentLineObject['M' + i])
        for (let j = 1; j < 9; j++) {
          tempArray.push(currentLineObject['DM' + i.toString() + j.toString()])
        }
        tempFullArray.push(tempArray)
      }

      //В зависимости от первого элемента обрезать по количеству и "назвать" из массива
      let resObj = {}
      tempFullArray.forEach(function (r) {
        let id = parseInt(r[0])
        let currentLayoutKeys = []
        let outObject = {}


        if (ids.includes(id)) {
          if ([11, 12, 15, 23].includes(id)) {
            let layoutInside = []
            let jsonString = ''
            let tmp = ''

            //Уникальные решения
            switch (id) {
              case 11:
                //layout11: [{"DM11":0, "DM12":0, "DM13":0, "DM14":0, "DM15":0, "DM16":0, "DM17":0, "DM18":0}]
                layouts['layout11ObjectOne'].forEach(function (key, index) {
                  return Object.assign(outObject, {[key]: r[index + 1]})
                }, {})
                layoutInside.push(outObject)
                Object.assign(resObj, {['layout11']: layoutInside})
                break;
              case 12:
                // layout12: [{"DM21": 0,"DM22": 0, "DM23":0, layoutInside: [{"DM24","DM25"},{"DM26", "DM27"}] }]
                jsonString = `[{"DM21": "${r[1]}","DM22": "${r[2]}", "DM23":"${r[3]}", "layoutInside": [{"DM24": "${r[4]}","DM25":"${r[5]}"},{"DM26":"${r[6]}", "DM27":"${r[7]}"}] }]`
                tmp = JSON.parse(jsonString)
                Object.assign(resObj, {['layout12']: tmp})
                break;
              case 15:
                //layout15: [{"DM51": 0, "DM52": 0, "DM55": 0, "DM56": 0, "DM57": 0, "DM58": 0, layoutInside: [{"DM53": 0, "DM54": 0}] }]
                jsonString = `[{"DM51": "${r[1]}", "DM52": "${r[2]}", "DM55": "${r[5]}", "DM56": "${r[6]}", "DM57": "${r[7]}", "DM58": "${r[8]}", "layoutInside": [{"DM53": "${r[3]}", "DM54": "${r[4]}"}] }]`
                tmp = JSON.parse(jsonString)
                Object.assign(resObj, {['layout15']: tmp})
                break;
              case 23:
                //layout23 [{"DM301": "0"}]
                jsonString = `[{"DM301": "${r[1]}"}]`
                tmp = JSON.parse(jsonString)
                Object.assign(resObj, {['layout23']: tmp})
            }
          } else {
            outObject = {}
            currentLayoutKeys = layouts['layout' + id.toString()]

            currentLayoutKeys.forEach(function (key, index) {
              return Object.assign(outObject, {[key]: r[index + 1]})
            }, {})
            Object.assign(resObj, outObject)
          }
        }

      })
      return resObj
    },
    getEventsArray(currentLineObject) {
      let r = this.filterObjectByKeys(this.EventKeys, currentLineObject)
      let jsonString = ''
      let tmp = ''

      //[{"UP": 0, "UPP": 0, "PTK": 0}, {"UP": 0, "PTK": 0}, {"UP": 0, "PTK": 0}]
      jsonString = `[{"UP": "${r['UP1']}", "UPP": "${r['UP1P']}", "PTK": "${r['PTK1']}"}, {"UP": "${r['UP2']}", "PTK": "${r['PTK2']}"}, {"UP": "${r['UP3']}", "PTK": "${r['PTK3']}"}]`
      tmp = JSON.parse(jsonString)

      return tmp
    },
    downloadAsJSON(data) {
      data = JSON.stringify(data)
      let a = document.createElement("a");
      let file = new Blob([data], {type: 'application/json'});
      a.href = URL.createObjectURL(file);
      a.download = "convertedFromXLSX.json";
      a.click();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

input[type="file"] {
  display: none;
}
</style>
